@import "_secondary";

ul li {
  list-style: none;
}

h3 {
  font-weight: normal;
}

h4 {
  font-weight: bold;
}

p, li, th, td {
  line-height: 1.8;
}

/* ------------------------------------------------------------------------
  ★hero
--------------------------------------------------------------------------*/
.hero {
  background-image: url(/assets/images/policy/img_hero.jpg);
  @extend %hero;
}

/* ------------------------------------------------------------------------
  ★contents
--------------------------------------------------------------------------*/
.mb1 {margin-bottom: 1rem;}
.mb2 {margin-bottom: 2rem;}
.mb3 {margin-bottom: 3rem;}
.mb4 {margin-bottom: 4rem;}
.mb5 {margin-bottom: 5rem;}

main {
  background: #f8f7f2;
}

.contents-item {
  flex-wrap: wrap;
  //box-sizing: border-box;
  .contents__inner {
    a {
      display: inline;
      text-decoration: underline;
      word-break: break-word;
      &[target="_blank"]::after {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        background: url(/assets/images/common/icon/icon-blank.png) no-repeat;
        margin-left: .5em;
      }
    }
    ol li {
      margin-left: 2rem;
    }
    ul li {
      //text-indent: -1em;
      //padding-left: 1em;
      &::before {
        content: "・";
      }
    }
    table {
      th, td {
        border: 1px solid #333;
        padding: 1.5rem 1rem;
        vertical-align: middle;
        word-break: break-word;
      }
      tbody th {
        font-weight: normal;
      }
    }
    h6 {
      text-indent: -1.9em;
      padding-left: 1.9em;
    }
  }
}
@include mq('PC') {
  .contents-item {
    max-width: $PC_max;
    margin: auto;
    padding: 84px 120px 120px 118px;
    h3 {
      font-size: 4.6rem;
      margin-bottom: 46px;
      font-weight: 600;
    }
    .contents__inner {
      section + section {
        margin-top: 90px;
      }
      a {
        transition: color 0.2s ease;
        & + a {
          margin-top: 1rem;
        }
        &:hover {
          color: #c00;
        }
      }
      h4 {
        font-size: 2.4rem;
        margin-top: 5rem;
        margin-bottom: 3rem;
        padding-left: 20px;
        border-left: 5px solid #9a7c5f;
      }
      h5 {
        font-size: 2.0rem;
        margin-top: 4rem;
      }
      h6 {
        font-size: 2.0rem;
        margin-top: 4rem;
        & + p {
          margin-top: 2rem;
        }
      }
    }
  }
  .cookiepolicy .contents-item {
    .contents__inner {
      h4 {
        padding-left: calc(10px + 1.3em);
        text-indent: -1.3em;
      }
    }
  }
}
@include mq('SP') {
  .contents-item {
    padding: 40px 20px 0;
    h3 {
      margin-bottom: 4rem;
      font-size: 3.6rem;
    }
    .contents__inner {
      h4 {
        font-size: 2rem;
        margin-top: 4rem;
        margin-bottom: 2rem;
        padding-left: 10px;
        border-left: 3px solid #9a7c5f;
      }
      h5 {
        margin-top: 2rem;
      }
      h6 {
        margin-top: 2rem;
        & + p {
          margin-top: 1rem;
        }
      }
    }
  }
  .cookiepolicy .contents-item {
    .contents__inner {
      h4 {
        padding-left: calc(10px + 1.3em);
        text-indent: -1.3em;
      }
    }
  }
}
