@charset "UTF-8";
/**
* top, bottom, left, bottom
**/
/* ------------------------------------------------------------------------
  ★module
--------------------------------------------------------------------------*/
h3 {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  letter-spacing: 0.02em;
  text-align: center;
  color: #9a7c5f;
  text-align: left;
  display: flex;
  line-height: 1.4;
}

h3::after {
  border-bottom: 1px dotted #9a7c5f;
  content: "";
  flex-grow: 1;
  margin-left: 20px;
}

@media screen and (min-width: 768px) {
  h3 {
    font-size: 4.6rem;
  }
}

@media screen and (max-width: 767px) {
  h3 {
    font-size: 3.6rem;
    font-weight: 500;
  }
}

h4, h5, h6 {
  line-height: 1.4;
}

.button {
  display: inline-block;
  width: 100%;
  background: url(/assets/images/top/pattern_transparent.png) repeat left top;
  font-size: 2rem;
  font-family: "Oswald", sans-serif;
  color: #ffffff;
  text-align: center;
  border: 1px solid #ffffff;
}

@media screen and (min-width: 768px) {
  .button {
    min-width: 240px;
    padding: 40px 0;
    font-size: 18px;
  }
}

/* ------------------------------------------------------------------------
  ★sns
--------------------------------------------------------------------------*/
@media screen and (min-width: 768px) {
  .sns.pc {
    position: absolute;
    top: 764px;
    bottom: auto;
  }
  .sns.pc .sns__item {
    box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.18);
  }
  .sns.pc .sns__item#fb {
    width: 40px;
    height: 40px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%208%2016%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22rgb(255%2C%20255%2C%20255)%22%20d%3D%22M5.319%2C15.998%20L1.771%2C15.998%20L1.771%2C7.997%20L-0.001%2C7.997%20L-0.001%2C5.240%20L1.771%2C5.240%20L1.771%2C3.584%20C1.771%2C1.336%202.772%2C-0.003%205.616%2C-0.003%20L7.983%2C-0.003%20L7.983%2C2.756%20L6.503%2C2.756%20C5.396%2C2.756%205.323%2C3.141%205.323%2C3.859%20L5.318%2C5.240%20L7.999%2C5.240%20L7.685%2C7.997%20L5.318%2C7.997%20L5.318%2C15.998%20L5.319%2C15.998%20L5.319%2C15.998%20Z%22%2F%3E%3C%2Fsvg%3E");
    background-size: 100%;
    background-position: left top;
    background-size: 40px 20px;
    background-position: center center;
  }
  .sns.pc .sns__item#tw {
    width: 40px;
    height: 40px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2014%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22rgb(255%2C%20255%2C%20255)%22%20d%3D%22M14.114%2C2.212%20C14.792%2C1.774%2015.313%2C1.082%2015.556%2C0.256%20C14.922%2C0.662%2014.220%2C0.956%2013.472%2C1.114%20C12.875%2C0.427%2012.021%2C-0.001%2011.076%2C-0.001%20C9.264%2C-0.001%207.795%2C1.581%207.795%2C3.533%20C7.795%2C3.809%207.822%2C4.079%207.878%2C4.337%20C5.151%2C4.189%202.733%2C2.784%201.113%2C0.644%20C0.830%2C1.168%200.668%2C1.774%200.668%2C2.422%20C0.668%2C3.647%201.248%2C4.729%202.129%2C5.365%20C1.591%2C5.346%201.085%2C5.185%200.641%2C4.923%20L0.641%2C4.966%20C0.641%2C6.678%201.772%2C8.107%203.275%2C8.431%20C2.999%2C8.514%202.710%2C8.556%202.410%2C8.556%20C2.199%2C8.556%201.992%2C8.534%201.793%2C8.493%20C2.210%2C9.897%203.422%2C10.919%204.859%2C10.947%20C3.735%2C11.895%202.319%2C12.460%200.782%2C12.460%20C0.517%2C12.460%200.255%2C12.445%20-0.002%2C12.411%20C1.451%2C13.413%203.177%2C13.999%205.031%2C13.999%20C11.069%2C13.999%2014.369%2C8.613%2014.369%2C3.942%20C14.369%2C3.789%2014.367%2C3.636%2014.361%2C3.485%20C15.002%2C2.987%2015.560%2C2.365%2015.999%2C1.656%20C15.410%2C1.937%2014.777%2C2.127%2014.114%2C2.212%20L14.114%2C2.212%20Z%22%2F%3E%3C%2Fsvg%3E");
    background-size: 100%;
    background-position: left top;
    background-size: 18px 20px;
    background-position: center center;
  }
  .sns.pc .sns__item#in {
    width: 40px;
    height: 40px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22rgb(255%2C%20255%2C%20255)%22%20d%3D%22M0.402%2C5.128%20L3.521%2C5.128%20L3.521%2C15.996%20L0.402%2C15.996%20L0.402%2C5.128%20L0.402%2C5.128%20ZM1.882%2C3.768%20L1.859%2C3.768%20C0.731%2C3.768%20-0.002%2C2.937%20-0.002%2C1.884%20C-0.002%2C0.812%200.752%2C-0.002%201.904%2C-0.002%20C3.054%2C-0.002%203.762%2C0.810%203.785%2C1.882%20C3.785%2C2.935%203.054%2C3.768%201.882%2C3.768%20L1.882%2C3.768%20L1.882%2C3.768%20ZM15.999%2C15.998%20L12.463%2C15.998%20L12.463%2C10.372%20C12.463%2C8.899%2011.910%2C7.895%2010.693%2C7.895%20C9.763%2C7.895%209.245%2C8.574%209.004%2C9.228%20C8.914%2C9.461%208.929%2C9.788%208.929%2C10.116%20L8.929%2C15.998%20L5.425%2C15.998%20C5.425%2C15.998%205.471%2C6.033%205.425%2C5.128%20L8.929%2C5.128%20L8.929%2C6.835%20C9.136%2C6.088%2010.255%2C5.023%2012.041%2C5.023%20C14.258%2C5.023%2015.999%2C6.588%2015.999%2C9.953%20L15.999%2C15.998%20L15.999%2C15.998%20L15.999%2C15.998%20Z%22%2F%3E%3C%2Fsvg%3E");
    background-size: 100%;
    background-position: left top;
    background-size: 18px 18px;
    background-position: center center;
  }
}

/* ------------------------------------------------------------------------
  ★hero
--------------------------------------------------------------------------*/
.hero {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.hero h2 {
  text-align: center;
  font-family: "Oswald", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Hiragino Kaku Gothic ProN", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", Meiryo, sans-serif;
  color: #ffffff;
  position: relative;
  z-index: 10;
}

.hero h2 .hero-ttl__head {
  display: block;
}

.hero h2 .hero-ttl_sub {
  display: block;
  font-weight: 600;
}

.hero::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.48);
  background-repeat: repeat;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
}

.hero #hero_movie {
  position: absolute;
  z-index: 1;
}

.hero .modal__movie {
  display: block;
  position: absolute;
  left: 40px;
  bottom: 40px;
  z-index: 10;
}

.hero .modal__movie::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0;
  top: 0;
}

.hero .modal__movie::after {
  content: "";
  display: block;
  width: 48px;
  height: 48px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20id%3D%22%22%20data-name%3D%22%20213%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2049%2049%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%20%7Bfill%3A%20none%3Bstroke%3A%20%23fff%3Bstroke-width%3A%201px%3B%7D.cls-2%20%7Bfill%3A%20%23fff%3Bfill-rule%3A%20evenodd%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Ccircle%20id%3D%22%22%20data-name%3D%22%22%20class%3D%22cls-1%22%20cx%3D%2224.5%22%20cy%3D%2224.5%22%20r%3D%2224%22%2F%3E%3Ccircle%20id%3D%22-2%22%20data-name%3D%22%22%20class%3D%22cls-1%22%20cx%3D%2224.5%22%20cy%3D%2224.5%22%20r%3D%2224%22%2F%3E%3Crect%20id%3D%22%22%20data-name%3D%22%22%20class%3D%22cls-1%22%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2248%22%20height%3D%2248%22%20rx%3D%2224%22%20ry%3D%2224%22%2F%3E%3Cpath%20id%3D%22%22%20data-name%3D%22%22%20class%3D%22cls-2%22%20d%3D%22M1754%2C832l16%2C8-16%2C8V832Z%22%20transform%3D%22translate(-1735.5%20-815.5)%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.hero .modal__movie .modal__movie--nocookie {
  display: none;
}

@media screen and (min-width: 768px) {
  .hero {
    height: 680px;
  }
  .hero h2 {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    font-size: 7.8rem;
    font-weight: 550;
    letter-spacing: 0.06em;
    transform: translate(-50%, -50%);
  }
  .hero h2 .hero-ttl__head {
    margin-bottom: 30px;
    font-size: 4rem;
  }
  .hero h2 .hero-ttl_sub {
    margin-top: 30px;
    font-size: 4rem;
  }
  .hero .modal__movie {
    transition: opacity 0.2s ease;
  }
  .hero .modal__movie:hover {
    opacity: 0.7;
  }
}

@media screen and (max-width: 767px) {
  .hero {
    background-size: auto 100%;
    height: 50vh;
    text-align: center;
    overflow: hidden;
  }
  .hero h2 {
    position: absolute;
    left: 50%;
    top: 50%;
    width: calc(100% - 40px);
    font-size: 4.6rem;
    letter-spacing: 0.05em;
    line-height: 1.4;
    transform: translate(-50%, -50%);
  }
  .hero h2 .hero-ttl__head {
    margin-bottom: 10px;
    font-size: 1.6rem;
  }
  .hero h2 .hero-ttl_sub {
    margin-top: 10px;
    font-size: 1.6rem;
    letter-spacing: 0.03em;
  }
  .hero .modal__movie {
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }
  .hero .modal__movie::before {
    display: none;
  }
  .hero .modal__movie img {
    opacity: 0;
  }
}

@media screen and (orientation: landscape) {
  .hero {
    background-size: cover;
  }
}

/* ------------------------------------------------------------------------
  ★main
--------------------------------------------------------------------------*/
main {
  display: block;
  background: #f8f7f2;
  background: -moz-linear-gradient(180deg, #f8f7f2 80%, white 80%, white 100%);
  background: -webkit-linear-gradient(180deg, #f8f7f2 80%, white 80%, white 100%);
  background: linear-gradient(180deg, #f8f7f2 80%, white 80%, white 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f8f7f2",endColorstr="#ffffff",GradientType=1);
}

@media screen and (max-width: 767px) {
  main {
    padding-bottom: 90px;
  }
}

.contents__textlink {
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .contents__textlink {
    transition: color 0.2s ease;
  }
  .contents__textlink:hover {
    color: #c00;
  }
}

ul li {
  list-style: none;
}

h3 {
  font-weight: normal;
}

h4 {
  font-weight: bold;
}

p, li, th, td {
  line-height: 1.8;
}

/* ------------------------------------------------------------------------
  ★hero
--------------------------------------------------------------------------*/
.hero {
  background-image: url(/assets/images/policy/img_hero.jpg);
}

/* ------------------------------------------------------------------------
  ★contents
--------------------------------------------------------------------------*/
.mb1 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 2rem;
}

.mb3 {
  margin-bottom: 3rem;
}

.mb4 {
  margin-bottom: 4rem;
}

.mb5 {
  margin-bottom: 5rem;
}

main {
  background: #f8f7f2;
}

.contents-item {
  flex-wrap: wrap;
}

.contents-item .contents__inner a {
  display: inline;
  text-decoration: underline;
  word-break: break-word;
}

.contents-item .contents__inner a[target="_blank"]::after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  background: url(/assets/images/common/icon/icon-blank.png) no-repeat;
  margin-left: .5em;
}

.contents-item .contents__inner ol li {
  margin-left: 2rem;
}

.contents-item .contents__inner ul li::before {
  content: "・";
}

.contents-item .contents__inner table th, .contents-item .contents__inner table td {
  border: 1px solid #333;
  padding: 1.5rem 1rem;
  vertical-align: middle;
  word-break: break-word;
}

.contents-item .contents__inner table tbody th {
  font-weight: normal;
}

.contents-item .contents__inner h6 {
  text-indent: -1.9em;
  padding-left: 1.9em;
}

@media screen and (min-width: 768px) {
  .contents-item {
    max-width: 1440px;
    margin: auto;
    padding: 84px 120px 120px 118px;
  }
  .contents-item h3 {
    font-size: 4.6rem;
    margin-bottom: 46px;
    font-weight: 600;
  }
  .contents-item .contents__inner section + section {
    margin-top: 90px;
  }
  .contents-item .contents__inner a {
    transition: color 0.2s ease;
  }
  .contents-item .contents__inner a + a {
    margin-top: 1rem;
  }
  .contents-item .contents__inner a:hover {
    color: #c00;
  }
  .contents-item .contents__inner h4 {
    font-size: 2.4rem;
    margin-top: 5rem;
    margin-bottom: 3rem;
    padding-left: 20px;
    border-left: 5px solid #9a7c5f;
  }
  .contents-item .contents__inner h5 {
    font-size: 2.0rem;
    margin-top: 4rem;
  }
  .contents-item .contents__inner h6 {
    font-size: 2.0rem;
    margin-top: 4rem;
  }
  .contents-item .contents__inner h6 + p {
    margin-top: 2rem;
  }
  .cookiepolicy .contents-item .contents__inner h4 {
    padding-left: calc(10px + 1.3em);
    text-indent: -1.3em;
  }
}

@media screen and (max-width: 767px) {
  .contents-item {
    padding: 40px 20px 0;
  }
  .contents-item h3 {
    margin-bottom: 4rem;
    font-size: 3.6rem;
  }
  .contents-item .contents__inner h4 {
    font-size: 2rem;
    margin-top: 4rem;
    margin-bottom: 2rem;
    padding-left: 10px;
    border-left: 3px solid #9a7c5f;
  }
  .contents-item .contents__inner h5 {
    margin-top: 2rem;
  }
  .contents-item .contents__inner h6 {
    margin-top: 2rem;
  }
  .contents-item .contents__inner h6 + p {
    margin-top: 1rem;
  }
  .cookiepolicy .contents-item .contents__inner h4 {
    padding-left: calc(10px + 1.3em);
    text-indent: -1.3em;
  }
}
